/* ---------- Bradstrap v4 Global Updates --------- */
/* 8pt Grid System */
/* 8pt Spacers Extended */
/* Updating Base Colors (I don't believe in false greys.) */
/* ---------- Site Specific Variables Updates --------- */
/* ---------- Fonts --------- */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url(bootstrap.min.css);
@font-face {
  font-family: Avenir;
  src: url("../assets/fonts/Avenir-Book.woff"), url("../assets/fonts/Avenir-Book.woff2"), url("../assets/fonts/Avenir-Book.eot"), url("../assets/fonts/Avenir-Book.ttf"); }

@font-face {
  font-family: Avenir Heavy;
  src: url("../assets/fonts/Avenir-Heavy.woff"), url("../assets/fonts/Avenir-Heavy.woff2"), url("../assets/fonts/Avenir-Heavy.eot"), url("../assets/fonts/Avenir-Heavy.ttf"); }

/* ---------- Imports --------- */
/* ---------- Inportant --------- */
html,
body,
#root {
  color: #6D6E71;
  height: 100%;
  background-color: #eeeeee;
  background: linear-gradient(121.52deg, #F4F6F9 4.18%, #DBE3EC 100%); }
